import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { formatRichText } from "../utils"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const Conditions = ({ data }) => {
  const { plainpage, homepages } = data.strapi
  const { language, heading, richText } = plainpage
  const { header, footer } = homepages[0]
  const content = formatRichText(richText)
  const contentLength = content.length

  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language })
  })

  return (
    <Layout header={header} footer={footer}>
      <SEO title={heading} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <div className="title-small border-blue mb-2 mb-md-5">
              <h2 className="text-uppercase">{heading}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {content.map((richText, index) => {
            return (
              <div
                key={index}
                className={`col-12 ${
                  contentLength > 0 &&
                  index % 2 !== 1 &&
                  index + 1 === contentLength
                    ? "col-md-10 offset-md-1"
                    : "col-md-5"
                } ${index % 2 === 0 ? "offset-md-1" : ""}`}
                style={{ marginTop: "10px" }}
              >
                <Markdown>{richText}</Markdown>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Conditions

export const pageQuery = graphql`
  query Conditions($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      plainpage(id: $id) {
        language
        heading
        richText {
          richText
        }
      }
      homepages(where: $language) {
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }
    }
  }
`
